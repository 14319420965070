import { Box, Stack, Text, VisuallyHidden } from 'components/ui';
import { ReactComponent as Logo } from 'images/salon-studios-logo.svg';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

export type FooterLink = {
  title: string;
  slug: string;
  secondary?: FooterLink[];
};

export interface FooterProps {
  primaryLinks: FooterLink[];
  secondaryLinks: FooterLink[];
}

const PrimaryWrapper = styled(Box)`
  --background-color: var(--color-black);
  --padding: var(--space-outer);
  --color: var(--color-white);

  display: grid;
  gap: var(--space-2);

  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: min-content 1fr;
    gap: var(--space-6);
    justify-items: end;
  }

  svg {
    width: 170px;
  }

  ul {
    list-style: none;
  }

  /* Top level nav list */
  > ul {
    @media screen and (min-width: ${breakpoints.large}) {
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-5);
    }
  }

  li {
    @media screen and (min-width: ${breakpoints.large}) {
      margin: 0;
    }
  }

  ul li ul {
    margin-top: var(--space-0);
  }

  ul li ul li {
    --font-size: calc(var(--font-size-xxx-small) / 1.1);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SecondaryWrapper = styled(Box)`
  --background-color: var(--color-pink);
  --padding: var(--space-2) var(--space-outer);
  --font-size: calc(var(--font-size-xxx-small) / 1.1);

  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-1);
    list-style: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterStyled = styled.footer`
  margin-top: var(--space-outer);
`;

const Footer: FC<FooterProps> = ({ primaryLinks, secondaryLinks }) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterStyled role='contentinfo'>
      <PrimaryWrapper as='nav'>
        <Link href='/'>
          <a>
            <Logo aria-hidden={true} />
            <VisuallyHidden>Salon Studios</VisuallyHidden>
          </a>
        </Link>
        <Stack as='ul' space={1}>
          {primaryLinks.map((primaryLink, index) => (
            <Text
              key={`${primaryLink.title}-${index}`}
              as='li'
              fontSize='xxxSmall'
              fontWeight='bold'
            >
              <Link href={primaryLink.slug}>
                <a>{primaryLink.title}</a>
              </Link>
              {primaryLink.secondary && (
                <Stack as='ul' space={0}>
                  {primaryLink.secondary?.map((secondaryLink, index) => (
                    <Text
                      key={`${secondaryLink.title}-${index}`}
                      as='li'
                      fontWeight='regular'
                    >
                      <Link href={secondaryLink.slug}>
                        <a>{secondaryLink.title}</a>
                      </Link>
                    </Text>
                  ))}
                </Stack>
              )}
            </Text>
          ))}
        </Stack>
      </PrimaryWrapper>
      <SecondaryWrapper>
        <ul>
          <Text as='li'>
            &copy; {currentYear} Salon Studios. All Rights Reserved.
          </Text>
          {secondaryLinks.map((link, index) => (
            <Text key={`${link.title}-${index}`} as='li'>
              <Link href={link.slug}>
                <a>{link.title}</a>
              </Link>
            </Text>
          ))}
        </ul>
      </SecondaryWrapper>
    </FooterStyled>
  );
};

export default Footer;
