import { FC } from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { generateCustomPropertyKey, Theme } from 'styles/theme';

type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type HeadingVariants = 'serif' | 'sans-serif';

export interface HeadingProps {
  level: HeadingLevels;
  color?: keyof Theme['colors'];
  variant?: 'serif' | 'sans-serif';
  as?: HeadingLevels;
}

type StyledHeadingProps = Omit<HeadingProps, 'level'>;

const baseStyles = (variant: HeadingVariants) => {
  return css`
    color: var(--color, var(--color-black));
    font-weight: var(--font-weight, var(--font-weight-regular));
    font-size: var(--font-size, var(--font-size-xxxx-large));
    font-family: var(--font-family, var(--font-heading));
    line-height: var(--line-height-small);

    ${variant === 'sans-serif' &&
    css`
      /* Overrides for sans-serif variant */
      --font-family: var(--font-body);
      --font-weight: var(--font-weight-bold);
    `}
  `;
};

const H1 = styled.h1<StyledHeadingProps>`
  ${(props) => baseStyles(props.variant || 'serif')}

  --font-size: var(--font-size-xxxx-large);
`;

const H2 = styled.h2<StyledHeadingProps>`
  ${(props) => baseStyles(props.variant || 'serif')}

  --font-size: var(--font-size-xxx-large);
`;

const H3 = styled.h3<StyledHeadingProps>`
  ${(props) => baseStyles(props.variant || 'serif')}

  --font-size: var(--font-size-xx-large);
`;

const H4 = styled.h4<StyledHeadingProps>`
  ${(props) => baseStyles(props.variant || 'serif')}

  --font-size: var(--font-size-x-large);
`;

const H5 = styled.h4<StyledHeadingProps>`
  ${(props) => baseStyles(props.variant || 'serif')}

  --font-size: var(--font-size-large);
`;

const Heading: FC<HeadingProps> = ({
  level = 'h1',
  variant = 'serif',
  color,
  as,
  children,
}) => {
  const colorVal =
    color && `var(${generateCustomPropertyKey('colors', color)})`;
  let HeadingComponent: StyledComponent<typeof level, any, StyledHeadingProps>;

  switch (level) {
    case 'h1':
      HeadingComponent = H1;
      break;
    case 'h2':
      HeadingComponent = H2;
      break;
    case 'h3':
      HeadingComponent = H3;
      break;
    case 'h4':
      HeadingComponent = H4;
      break;
    case 'h5':
      HeadingComponent = H5;
      break;
    default:
      HeadingComponent = H1;
      break;
  }

  return (
    <HeadingComponent
      as={as}
      variant={variant}
      style={{
        '--color': colorVal,
      }}
    >
      {children}
    </HeadingComponent>
  );
};

export default Heading;
