import Footer from 'components/footer';
import Header from 'components/header';
import PreviewBanner from 'components/preview-banner';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { SETTINGS } from 'settings/config';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { SharedData } from 'utils/queries';

type Link = {
  slug: string;
  title: string;
};

export interface LayoutProps {
  spaceLinks?: SharedData['spaceInfoPaths'];
  showFooter?: boolean;
  preview?: boolean;
}

const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: min-content auto min-content;
  min-height: 100vh;
`;

const Main = styled(motion.main)`
  width: 100%;
  max-width: var(--size-main);
  margin: 0 auto;
  overflow: hidden;

  > * {
    &:last-child {
      &[data-bg='white'] {
        padding-bottom: 0;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 0 var(--space-outer) var(--space-outer);
  }
`;

const primaryNavLinks = (spaceLinks?: Link[]) => [
  SETTINGS.routes.home,
  {
    slug: SETTINGS.routes.studioSpace.slug,
    title: SETTINGS.routes.studioSpace.title,
    secondary: spaceLinks?.map(({ title, slug }) => ({
      title,
      slug: `${SETTINGS.routes.studioSpace.slug}/${slug}`,
    })),
  },
  SETTINGS.routes.services,
  SETTINGS.routes.locations,
  SETTINGS.routes.about,
  SETTINGS.routes.contact,
];

const Layout: FC<LayoutProps> = ({
  children,
  showFooter,
  spaceLinks,
  preview,
}) => {
  const router = useRouter();

  return (
    <>
      {preview && <PreviewBanner />}
      <LayoutContainer>
        <Header
          links={primaryNavLinks(spaceLinks)}
          activeLink={router.asPath}
        />
        <Main
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
        >
          {children}
        </Main>
        {showFooter && (
          <Footer
            primaryLinks={primaryNavLinks(spaceLinks)}
            secondaryLinks={[]}
          />
        )}
      </LayoutContainer>
    </>
  );
};

export default Layout;

Layout.defaultProps = {
  showFooter: true,
};
