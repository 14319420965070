import { groq } from 'next-sanity';
import { getClient } from './sanity-server';

const basicContent = groq`
  ...,
  markDefs[] {
    ...,
    _type == "internalLink" => {
      "slug": @.reference->slug,
      "type": @.reference->_type
    }
  },
  _type == "iconList" => {
    ...,
    items[] {
      ...,
      icon->
    }
  },
`;

const modules = groq`
  modules[] {
    ...,
    _type == "contentBlock" => {
      ...,
      text[] {
        ${basicContent}
      }
    },
    _type == "inclusions" => {
      ...,
      specialistInclusion {
        ...,
        specialists[] {
          ...,
          specialist->
        }
      }
    }
  }
`;

const hero = groq`
  hero {
    ...,
    text[] {
      ${basicContent}
    }
  }
`;

const specialist = groq`
  ...,
  location->,
  specialistType->,
  services[] {
    ...,
    service->
  }
`;

export type SpecialistWithExpandedRefs = Omit<
  Sanity.Schema.Specialist,
  'location' | 'specialistType' | 'services'
> & {
  location?: Sanity.Schema.Location;
  specialistType?: Sanity.Schema.SpecialistType;
  services?: Array<
    Sanity.Keyed<{
      _type: 'service';
      service?: Sanity.Schema.Service;
      price?: string;
    }>
  >;
};

export const homeQuery = groq`
  *[_type=="home"][0] {
    ...,
    ${hero}
  }
`;

export const aboutQuery = groq`
  *[_type=="about"][0] {
    ...,
    ${hero},
    ${modules}
  }
`;

export const teamMembersQuery = groq`
  *[_type=="teamMember"] | order(order asc)
`;

export const spaceInfoPathsQuery = groq`
  *[_type == "spaceInfo" && defined(slug.current)] | order(order asc)[] {
    "slug": slug.current,
    "title": hero.title
  }
`;

export const specialistPathsQuery = groq`
  *[_type == "specialist" && defined(slug.current)] | order(order asc)[] {
    "slug": slug.current,
    "title": hero.title
  }
`;

export const spaceInfoQuery = groq`
  *[_type == "spaceInfo" && slug.current == $slug][0] {
    ...,
    ${modules}
  }
`;

export const locationsQuery = groq`
  *[_type == "location"]
`;

export const locationsTextQuery = groq`
  *[_type == "siteSettings"][0]{
    "text": locationsPageText[]
  }
`;

export const getSpaceInfoPaths = async (preview: boolean) =>
  getClient(preview).fetch<{ slug: string; title: string }[]>(
    spaceInfoPathsQuery
  );

export type SharedData = {
  spaceInfoPaths: { slug: string; title: string }[];
  locations: Sanity.Schema.Location[];
};

export const sharedDataQuery = groq`
  {
    "spaceInfoPaths": ${spaceInfoPathsQuery},
    "locations": ${locationsQuery}
  }
`;

export type SpecialistTypes = {
  _id: Sanity.Schema.SpecialistType['_id'];
  name?: Sanity.Schema.SpecialistType['name'];
  relatedSpecialists: {
    services?: {
      _id: Sanity.Schema.Service['_id'];
      name?: Sanity.Schema.Service['name'];
    }[];
  }[];
}[];

export const specialistTypesQuery = groq`
  *[_type=="specialistType"] | order(name asc) {
    _id,
    name,
    "relatedSpecialists": *[_type=='specialist' && references(^._id)] {
      "services": services[].service->{ _id, name }
    }
  }
`;

export const specialistQuery = groq`
  *[_type=='specialist' && slug.current == $slug][0] {
    ${specialist}
  }
`;

export const specialistsQuery = groq`
  *[_type=='specialist'] {
    ${specialist}
  }
`;

export const getSpecialistPaths = async (preview: boolean) =>
  getClient(preview).fetch<{ slug: string; title: string }[]>(
    specialistPathsQuery
  );
