import styled, { css } from 'styled-components';

export interface ButtonProps {
  variant?: 'outline' | 'primary' | 'secondary';
}

const Button = styled.button<ButtonProps>`
  padding: 1em 2.5em;
  color: var(--color, var(--color-white));
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size, var(--font-size-xxx-small));
  line-height: var(--line-height-medium);
  text-decoration: none;
  background-color: var(--button-background-color, var(--color-black));
  border: 0;
  border: 2px solid;
  border-color: var(--border-color, var(--color-black));
  border-radius: var(--border-radius, var(--radius-small));
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${({ variant }) => {
    if (variant === 'outline') {
      return css`
        /* Outline variant */
        --button-background-color: transparent;
        --color: var(--color-black);
        --border-color: var(--color-black);

        /* stylelint-disable */
        &:hover {
          --button-background-color: var(--color-black);
          --color: var(--color-white);
        }
      `;
    }

    if (variant === 'secondary') {
      return css`
        /* Secondary variant */
        --button-background-color: var(--color-pink100);
        --color: var(--color-black);
        --border-color: var(--color-pink100);
      `;
    }
  }}

  /* When used as a reach tab */
  &[data-reach-tab]:not([data-selected]) {
    --button-background-color: transparent;
    --color: var(--color-black);
    --border-color: var(--color-black);
    border-bottom-width: 2px;
  }

  &[data-reach-tab][data-selected] {
    border-bottom-color: var(--color-black);
  }

  /* When used as a link */
  a& {
    display: inline-block;
  }
`;

export default Button;
