import styled from 'styled-components';

const Box = styled.div`
  padding: var(--padding);
  color: var(--color, var(--color-black));
  background-color: var(--background-color, var(--color-white));

  * {
    color: inherit;
  }
`;

export default Box;
