import Flourish, { FlourishTypes } from 'components/flourish';
import { Box } from 'components/ui';
import { FC } from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import theme, {
  breakpoints,
  generateCustomPropertyKey,
  Theme,
} from 'styles/theme';
import pattern from './backdrop-pattern.svg';

export interface BackdropProps {
  flourishTopLeft?: keyof typeof FlourishTypes;
  flourishBottomRight?: keyof typeof FlourishTypes;
  pattern?: boolean;
  backgroundColor?: keyof Theme['colors'];
  className?: string;
  style?: CSSProperties;
  homepage?: boolean;
}

const adjacentItemStyles = Object.keys(theme.colors).map(
  (color) =>
    css`
      &[data-bg='${color}'] + [data-bg='${color}'] {
        padding-top: 0;
      }
    `
);

const StyledFlourish = styled(Flourish)`
  position: absolute;
  height: var(--flourish-height);
  background: none;
  pointer-events: none;
`;

const FlourishWrapper = styled.div`
  --flourish-height: calc(var(--padding) * 2);

  height: var(--padding);

  /* Small flourish */
  &[data-variant=${FlourishTypes.small}] {
    &[data-position='top-left'] ${StyledFlourish} {
      transform: rotate(5deg) translate(-25%, -10%) scale(1.25);
    }

    &[data-position='bottom-right'] ${StyledFlourish} {
      transform: rotate(5deg) translate(25%, 10%) scale(-1.25);
    }
  }

  /* Green flourish */
  &[data-variant=${FlourishTypes.mediumGreen}] {
    &[data-position='top-left'] ${StyledFlourish} {
      transform: rotate(-15deg) translate(-55%, -25%) scale(-1.5);
    }

    &[data-position='bottom-right'] ${StyledFlourish} {
      transform: rotate(-5deg) translate(70%, 40%) scale(1.5);
    }
  }

  /* Yellow flourish */
  &[data-variant=${FlourishTypes.mediumYellow}] {
    &[data-position='top-left'] ${StyledFlourish} {
      transform: rotate(-25deg) translate(-50%, -50%) scale(-1.5);
    }

    &[data-position='bottom-right'] ${StyledFlourish} {
      transform: rotate(10deg) translate(50%, 30%) scale(-1.5);
    }
  }

  &[data-position='top-left'] ${StyledFlourish} {
    top: 0;
    left: 0;
  }

  &[data-position='bottom-right'] ${StyledFlourish} {
    right: 0;
    bottom: 0;
  }
`;

const Wrapper = styled(Box)<{ disablePaddingMobile?: boolean }>`
  --padding: var(--space-4);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &[data-pattern='true'] {
    @media screen and (min-width: ${breakpoints.medium}) {
      /* Extra spacing to accommodate for pattern */
      &::after {
        display: block;
        width: 100%;
        height: var(--padding);
        content: '';
      }

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(var(--padding) * 2);
        background: url(${pattern});
        background-position: center;
        content: '';
      }
    }
  }

  @media screen and (max-width: ${breakpoints.small}) {
    padding-bottom: ${(props) =>
      props.disablePaddingMobile ? 0 : 'var(--space-4)'};
  }

  @media screen and (min-width: ${breakpoints.small}) {
    --padding: var(--space-6);
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    --padding: var(--space-8);
  }

  @media screen and (min-width: ${breakpoints.large}) {
    --padding: var(--space-10);
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    --padding: var(--space-12);
  }

  /* Adjacent backgrounds of the same colour */
  ${adjacentItemStyles}
`;

const Backdrop: FC<BackdropProps> = ({
  backgroundColor,
  children,
  flourishTopLeft,
  flourishBottomRight,
  pattern,
  className,
  style,
  homepage,
}) => {
  const backgroundColorVal =
    backgroundColor &&
    `var(${generateCustomPropertyKey('colors', backgroundColor)})`;

  return (
    <Wrapper
      style={{ '--background-color': backgroundColorVal, ...style }}
      data-pattern={pattern}
      data-bg={backgroundColor || 'white'}
      className={className}
      disablePaddingMobile={homepage}
    >
      {flourishTopLeft && (
        <FlourishWrapper
          data-position='top-left'
          data-variant={flourishTopLeft}
        >
          <StyledFlourish variant={flourishTopLeft} />
        </FlourishWrapper>
      )}
      {children}
      {flourishBottomRight && (
        <FlourishWrapper
          data-position='bottom-right'
          data-variant={flourishBottomRight}
        >
          <StyledFlourish variant={flourishBottomRight} />
        </FlourishWrapper>
      )}
    </Wrapper>
  );
};

export default Backdrop;
