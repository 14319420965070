import { Children, FC } from 'react';
import styled from 'styled-components';

export interface ImageGridProps {}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`;

const Grid = styled.div`
  --grid-size: 6;

  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(var(--grid-size), 1fr);
  grid-template-columns: repeat(var(--grid-size), 1fr);
  gap: var(--space-0);
  width: 100%;
  height: 100%;

  /* Contains two images */
  &[data-children='2'] > * {
    &:nth-child(1) {
      grid-row: 1 / span 3;
      grid-column: 2 / -1;
    }
    &:nth-child(2) {
      grid-row: 4 / span 3;
      grid-column: 3 / -1;
    }
  }

  /* Contains three images */
  /* stylelint-disable */
  &[data-children='3'] > * {
    &:nth-child(1) {
      grid-row: 1 / span 3;
      grid-column: 2 / span 4;
    }
    &:nth-child(2) {
      grid-row: 4 / span 2;
      grid-column: 1 / span 3;
    }
    &:nth-child(3) {
      grid-row: 4 / span 3;
      grid-column: 4 / -1;
    }
  }
`;

const ImageGrid: FC<ImageGridProps> = ({ children }) => {
  const count = Children.count(children);
  return (
    <Wrapper>
      <Grid data-children={count}>{children}</Grid>
    </Wrapper>
  );
};

export default ImageGrid;
