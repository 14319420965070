import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: var(--space-1);
  text-align: center;
  background-color: var(--color-pink100);
`;

const PreviewBanner: FC = () => {
  return (
    <Wrapper>
      This page is a preview. <a href='/api/exit-preview'>Click here</a> to exit
      preview mode.
    </Wrapper>
  );
};

export default PreviewBanner;
