import { Box, Text, VisuallyHidden } from 'components/ui';
import { ReactComponent as Logo } from 'images/salon-studios-logo.svg';
import Link from 'next/link';
import { FC, useState } from 'react';
import { SETTINGS } from 'settings/config';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

export type HeaderLink = {
  title: string;
  slug: string;
  secondary?: HeaderLink[];
};

export interface HeaderProps {
  links: HeaderLink[];
  activeLink?: string;
}

const StyledHeader = styled(Box)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Wrapper = styled(Box)`
  --padding: calc(var(--space-outer) / 2.5) var(--space-outer);
  --background-color: var(--color-white);
  --secondary-nav-height: var(--space-6);

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  a {
    display: block;
    text-decoration: none;

    @media screen and (min-width: ${breakpoints.xLarge}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:hover {
      text-decoration: underline;
    }

    &::after {
      display: none;
      height: 0;
      overflow: hidden;
      font-weight: var(--font-weight-bold);
      visibility: hidden;
      content: attr(data-title);
      content: attr(data-title) / '';
      user-select: none;
      pointer-events: none;

      @media screen and (min-width: ${breakpoints.xLarge}) {
        display: block;
      }

      @media speech {
        display: none;
      }
    }
  }

  [data-active='true'] {
    font-weight: var(--font-weight-bold);
  }

  &::after {
    @media screen and (min-width: ${breakpoints.xLarge}) {
      display: block;
      width: 100%;
      height: 0;
      opacity: 0;
      transition: 0.25s ease-in-out;
      transition-delay: 0.25s;
      content: '';
    }
  }

  &[data-subnav-visible='true'] {
    &::after {
      @media screen and (min-width: ${breakpoints.xLarge}) {
        height: var(--secondary-nav-height);
        opacity: 1;
      }
    }
  }

  svg {
    display: block;
    width: 140px;
    margin-right: var(--space-4);
    color: #231f20;

    @media screen and (min-width: ${breakpoints.xLarge}) {
      width: 170px;
    }
  }
`;

const PrimaryList = styled.ul`
  position: absolute;
  top: 0;
  right: var(--space-2);
  display: grid;
  gap: var(--space-2);
  margin-left: auto;
  padding: var(--space-3);
  padding-top: var(--space-6);
  text-transform: uppercase;
  list-style: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  li {
    font-size: 0.875rem;
  }

  &[data-open='true'] {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    position: static;
    display: flex;
    gap: 0;
    padding: 0;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
  }

  a {
    padding: 0;

    @media screen and (min-width: ${breakpoints.xLarge}) {
      padding: var(--space-1) var(--space-1);
    }
  }
`;

const SecondaryList = styled.ul`
  display: grid;
  gap: var(--space-0);
  margin-top: var(--space-0);
  margin-left: var(--space-0);
  text-transform: none;
  list-style: none;

  @media screen and (min-width: ${breakpoints.xLarge}) {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--secondary-nav-height);
    margin-left: 0;
    padding: 0 var(--space-outer);
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - (var(--space-outer) * 2));
      height: 1px;
      background-color: var(--color-grey200);
      transform: translateX(-50%);
      content: '';
    }
  }

  [data-subnav-visible='false'] & {
    @media screen and (min-width: ${breakpoints.xLarge}) {
      display: none;
    }
  }

  li,
  a {
    height: 100%;
  }

  a {
    position: relative;
  }

  [data-active='true'] {
    @media screen and (min-width: ${breakpoints.xLarge}) {
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: calc(100% - var(--space-1) * 2);
        height: 6px;
        background-color: currentColor;
        transform: translateX(-50%);
        content: '';
      }
    }
  }
`;

// Thanks: https://jonsuh.com/hamburgers/
const ToggleButton = styled.div`
  z-index: 1;
  display: inline-flex;
  margin: 0;
  margin-left: auto;
  padding: var(--space-0);
  overflow: visible;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;

  @media screen and (min-width: ${breakpoints.xLarge}) {
    display: none;
  }

  > div {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 22px;

    div,
    div::before,
    div::after {
      position: absolute;
      width: 32px;
      height: 2px;
      background-color: currentColor;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
    }

    div {
      top: 50%;
      display: block;
      margin-top: -2px;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 75ms;

      &::before,
      &::after {
        display: block;
        content: '';
      }

      &::before {
        top: -10px;
        transition: top 75ms ease 0.12s, opacity 75ms ease;
      }

      &::after {
        right: 0;
        bottom: -10px;
        width: 50%;
        transition: bottom 75ms ease 0.12s,
          transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }

  &[data-open='true'] {
    transform: scale(0.65);
    transition: transform 0.25s ease-in-out;

    > div {
      div {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 75ms ease, opacity 75ms ease 0.12s;
        }

        &::after {
          bottom: 0;
          width: 32px;
          transform: rotate(-90deg);
          transition: bottom 75ms ease,
            transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        }
      }
    }
  }
`;

const Header: FC<HeaderProps> = ({ links, activeLink }) => {
  const [navOpen, setNavOpen] = useState(false);
  const linkParts = activeLink?.split('/').filter((link) => link !== '');

  // Only show the subnav if the parent is active
  const shouldShowSubnav = links
    .find((link) => link.slug === `/${linkParts?.[0]}`)
    ?.hasOwnProperty('secondary');

  return (
    <StyledHeader as='header'>
      <Wrapper as='nav' data-subnav-visible={shouldShowSubnav || false}>
        <Link href={SETTINGS.routes.home.slug}>
          <a>
            <Logo aria-hidden={true} />
            <VisuallyHidden>Salon Studios</VisuallyHidden>
          </a>
        </Link>
        <ToggleButton
          aria-label='Toggle menu'
          data-open={navOpen}
          onClick={() => setNavOpen((prev) => !prev)}
        >
          <div>
            <div></div>
          </div>
        </ToggleButton>
        <PrimaryList data-open={navOpen}>
          {links.map((primaryLink, index) => (
            <Text key={`${primaryLink.title}-${index}`} as='li'>
              <Link href={primaryLink.slug}>
                <a
                  data-active={
                    '/' + linkParts?.[0] === primaryLink.slug ||
                    (linkParts?.length === 0 && primaryLink.slug === '/')
                  }
                  data-title={primaryLink.title}
                >
                  {primaryLink.title}
                </a>
              </Link>
              {primaryLink.secondary && (
                <SecondaryList>
                  {primaryLink.secondary.map((secondaryLink, index) => (
                    <Text key={`${secondaryLink.title}-${index}`} as='li'>
                      <Link href={secondaryLink.slug}>
                        <a
                          data-active={
                            `/${linkParts?.join('/')}` === secondaryLink.slug
                          }
                          data-title={secondaryLink.title}
                        >
                          {secondaryLink.title}
                        </a>
                      </Link>
                    </Text>
                  ))}
                </SecondaryList>
              )}
            </Text>
          ))}
        </PrimaryList>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;
