import Head from 'next/head';
import React, { FC } from 'react';
import { SETTINGS } from 'settings/config';
import { urlForImage } from 'utils/sanity';

interface Props {
  title?: string;
  description?: string;
  image?: Sanity.Schema.Seo['ogImage'];
}

const SEO: FC<Props> = ({ title = '', description, image }) => {
  const ogImage =
    (image && urlForImage(image).width(1200).height(627).url()) ||
    SETTINGS.seo.defaultImage;

  let pageTitle: string;
  if (title === SETTINGS.seo.defaultTitle) {
    pageTitle = title;
  } else {
    pageTitle = `${title} | ${SETTINGS.seo.defaultTitle}`;
  }

  const pageDescription = description ?? SETTINGS.seo.defaultDescription;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property='og:title' content={pageTitle} />
      <meta name='description' content={pageDescription} />
      <meta property='og:description' content={pageDescription} />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content={pageDescription}></meta>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content={SETTINGS.seo.twitterSite} />
      {ogImage && (
        <>
          <meta property='og:image' content={ogImage} />
          <meta name='twitter:image' content={ogImage} />
        </>
      )}
    </Head>
  );
};

export default SEO;
