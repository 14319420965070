import { TextBlock } from 'components/ui';
import { createPortableTextComponent } from 'next-sanity';
import { FC } from 'react';
import { sanityConfig } from 'settings/config';
import { serializers } from './serializers';

interface BlockContentProps {
  blocks: any[] | any;
}

// Set up Portable Text serialization
export const PortableText = createPortableTextComponent({
  ...sanityConfig,
  serializers,
});

const BlockContent: FC<BlockContentProps> = ({ blocks }) => {
  return (
    <TextBlock>
      <PortableText blocks={blocks} serializers={serializers} />
    </TextBlock>
  );
};

export default BlockContent;
