import { FC, ForwardedRef, forwardRef, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import { inputBase } from '..';
import arrow from './select-arrow.svg';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  ref?: ForwardedRef<HTMLSelectElement>;
}

const Label = styled.label`
  display: block;
  margin-bottom: var(--space-0);
`;

const StyledSelect = styled.select`
  ${inputBase}

  padding-top: 0;
  padding-bottom: 0;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: calc(100% - var(--space-2)) 50%;
  appearance: none;
`;

const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  ({ id, label, children, ...props }, ref) => {
    return (
      <div>
        <Label htmlFor={id}>{label}</Label>
        <StyledSelect id={id} {...props} ref={ref}>
          {children}
        </StyledSelect>
      </div>
    );
  }
);

export default Select;
