import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

const StaggerGrid = styled.div`
  --gap: var(--space-5);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap);

  > * {
    &:nth-child(even) {
      @media screen and (min-width: ${breakpoints.medium}) {
        margin-top: calc(var(--gap) * 2);
      }
    }
  }
`;

export default StaggerGrid;
