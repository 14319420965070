import { ElementType, FC } from 'react';
import styled from 'styled-components';
import { breakpoints, generateCustomPropertyKey, Theme } from 'styles/theme';

type SpaceKey = keyof Theme['space'];

export interface StackProps {
  /** The amount of space between each element, pass a single space unit or object for responsive spacing */
  space:
    | SpaceKey
    | ({ initial: SpaceKey } & { [K in keyof typeof breakpoints]?: SpaceKey });
  as?: ElementType;
  className?: string;
}

const StyledStack = styled.div`
  --stack-space-initial: 0;

  margin: 0;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: var(--stack-space, var(--stack-space-initial));

    /* Default to stack space small, fallback to initial */
    @media screen and (min-width: ${breakpoints.small}) {
      --stack-space: var(--stack-space-small, var(--stack-space-initial));
    }

    /* Default to stack space medium, fallback to small, initial */
    @media screen and (min-width: ${breakpoints.medium}) {
      --stack-space: var(
        --stack-space-medium,
        var(--stack-space-small, var(--stack-space-initial))
      );
    }

    /* Default to stack space large, fallback to medium, small, initial */
    @media screen and (min-width: ${breakpoints.large}) {
      --stack-space: var(
        --stack-space-large,
        var(
          --stack-space-medium,
          var(--stack-space-small, var(--stack-space-initial))
        )
      );
    }

    /* Default to stack space x-large, fallback to large, medium, small, initial */
    @media screen and (min-width: ${breakpoints.xLarge}) {
      --stack-space: var(
        --stack-space-x-large,
        var(
          --stack-space-large,
          var(
            --stack-space-medium,
            var(--stack-space-small, var(--stack-space-initial))
          )
        )
      );
    }
  }
`;

/**
 * The stack component is responsible for creating even space between elements
 */
const Stack: FC<StackProps> = ({ children, space, as, className }) => {
  // Initial/fallback value, when passing a string or number
  const spaceValInitial =
    (typeof space === 'string' || typeof space === 'number') &&
    `var(${generateCustomPropertyKey('space', space)})`;

  // Responsive values, when passing an object
  let responsiveSpaceInitial;
  let responsiveSpaceSmall;
  let responsiveSpaceMedium;
  let responsiveSpaceLarge;
  let responsiveSpaceXLarge;

  if (typeof space === 'object') {
    responsiveSpaceInitial = space.initial
      ? `var(${generateCustomPropertyKey('space', space.initial)})`
      : spaceValInitial;
    responsiveSpaceSmall = space.small
      ? `var(${generateCustomPropertyKey('space', space.small)})`
      : responsiveSpaceInitial;
    responsiveSpaceMedium = space.medium
      ? `var(${generateCustomPropertyKey('space', space.medium)})`
      : responsiveSpaceSmall;
    responsiveSpaceLarge = space.large
      ? `var(${generateCustomPropertyKey('space', space.large)})`
      : responsiveSpaceMedium;
    responsiveSpaceXLarge = space.xLarge
      ? `var(${generateCustomPropertyKey('space', space.xLarge)})`
      : responsiveSpaceLarge;
  }

  return (
    <StyledStack
      className={className}
      as={as}
      style={{
        '--stack-space-initial': spaceValInitial || responsiveSpaceInitial,
        '--stack-space-small': responsiveSpaceSmall || spaceValInitial,
        '--stack-space-medium': responsiveSpaceMedium || spaceValInitial,
        '--stack-space-large': responsiveSpaceLarge || spaceValInitial,
        '--stack-space-x-large': responsiveSpaceXLarge || spaceValInitial,
      }}
    >
      {children}
    </StyledStack>
  );
};

export default Stack;
