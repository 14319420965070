import { Box, Text, TextProps } from 'components/ui';
import { FC } from 'react';
import styled from 'styled-components';
import { generateCustomPropertyKey, Theme } from 'styles/theme';

export interface TagProps extends TextProps {
  backgroundColor?: keyof Theme['colors'];
}

const Wrapper = styled(Box)`
  display: inline-block;
  padding: 0.25em 1.5em;
  border-radius: var(--radius-small);
`;

const Tag: FC<TagProps> = ({
  children,
  color,
  backgroundColor,
  ...textProps
}) => {
  const backgroundColorVal =
    backgroundColor &&
    `var(${generateCustomPropertyKey('colors', backgroundColor)})`;

  return (
    <Wrapper style={{ '--background-color': backgroundColorVal }}>
      <Text color={color} as='span' {...textProps}>
        {children}
      </Text>
    </Wrapper>
  );
};

export default Tag;
