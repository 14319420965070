import { ElementType, FC } from 'react';
import styled from 'styled-components';
import { generateCustomPropertyKey, Theme } from 'styles/theme';

export interface TextProps {
  color?: keyof Theme['colors'];
  fontSize?: keyof Theme['fontSizes'];
  fontWeight?: keyof Theme['fontWeights'];
  as?: ElementType;
  className?: string;
}

const StyledText = styled.p`
  color: var(--color, var(--color-black));
  font-weight: var(--font-weight, var(--font-weight-regular));
  font-size: var(--font-size, var(--font-size-xx-small));
  line-height: var(--line-height-x-large);
`;

const Text: FC<TextProps> = ({
  children,
  color,
  fontSize,
  fontWeight,
  className,
  as,
}) => {
  // Grab the relevant custom property names for prop overrides
  const colorVal =
    color && `var(${generateCustomPropertyKey('colors', color)})`;
  const fontSizeVal =
    fontSize && `var(${generateCustomPropertyKey('fontSizes', fontSize)})`;
  const fontWeightVal =
    fontWeight &&
    `var(${generateCustomPropertyKey('fontWeights', fontWeight)})`;

  return (
    <StyledText
      as={as}
      className={className}
      style={{
        '--color': colorVal,
        '--font-size': fontSizeVal,
        '--font-weight': fontWeightVal,
      }}
    >
      {children}
    </StyledText>
  );
};

export default Text;
