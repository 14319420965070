import { BlockContentProps } from '@sanity/block-content-to-react';
import { Heading, ListItem, Stack, Text } from 'components/ui';
import starIcon from 'images/star-icon.svg';
import Link from 'next/link';
import React, { ReactChild } from 'react';
import { urlForImage } from 'utils/sanity';

const BlockRenderer = ({ children, node }: any) => {
  const style = node.style || 'normal';

  switch (style) {
    case 'h1':
      return (
        <Heading variant='sans-serif' level='h1'>
          {children}
        </Heading>
      );
    case 'h2':
      return (
        <Heading variant='sans-serif' level='h2'>
          {children}
        </Heading>
      );
    case 'h3':
      return (
        <Heading variant='sans-serif' level='h3'>
          {children}
        </Heading>
      );
    case 'h4':
      return (
        <Heading variant='sans-serif' level='h4'>
          {children}
        </Heading>
      );
    default:
      return <Text>{children}</Text>;
  }
};

const IconListRenderer = ({
  children,
  node,
}: {
  children: ReactChild;
  node: Sanity.Schema.IconList;
}) => {
  return (
    <Stack space={1} as='ul'>
      {node.items?.map((item) => {
        const icon = item.icon && urlForImage((item as any).icon.image).url();
        return (
          <ListItem
            key={item._key}
            image={icon || ''}
            style={{ '--icon-size': '2em' }}
          >
            {item.text}
          </ListItem>
        );
      })}
    </Stack>
  );
};

const ListRenderer = ({ children }: { children: ReactChild }) => {
  return (
    <Stack space={1} as='ul'>
      {children}
    </Stack>
  );
};

const ListItemRenderer = ({ children }: { children: ReactChild }) => {
  return <ListItem image={starIcon}>{children}</ListItem>;
};

export const serializers: BlockContentProps['serializers'] = {
  types: {
    block: BlockRenderer,
    iconList: IconListRenderer,
  },
  list: ListRenderer as any,
  listItem: ListItemRenderer as any,
  marks: {
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    internalLink: ({ mark, children }) => {
      if (mark.slug) {
        const { slug = {}, blank } = mark;
        const href = `/${slug}`;
        return (
          <Link href='/[slug]' as={href}>
            {blank ? (
              <a target='_blank' rel='noopener noreferrer'>
                {children}
              </a>
            ) : (
              <a>{children}</a>
            )}
          </Link>
        );
      }

      return (
        <span>
          Unknown mark: <code>{mark._type}</code>.
        </span>
      );
    },
  },
};
