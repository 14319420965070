import styled, { css } from 'styled-components';

export interface ListItemProps {
  image?: string;
}

const ListItem = styled.li<ListItemProps>`
  ${({ image }) =>
    image &&
    css`
      --icon-size: 1em;

      display: flex;
      align-items: center;
      list-style: none;

      &::before {
        position: relative;
        top: -0.1em;
        display: block;
        flex-shrink: 0;
        width: var(--icon-size);
        height: var(--icon-size);
        margin-right: var(--space-2);
        background: url(${image});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        content: '';
      }
    `}
`;

export default ListItem;
