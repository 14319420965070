export const SETTINGS = {
  seo: {
    defaultTitle: process.env.NEXT_PUBLIC_NAME,
    defaultDescription: '',
    defaultImage: '',
    twitterSite: '',
  },
  routes: {
    home: {
      slug: '/',
      title: 'Home',
    },
    contact: {
      slug: '/contact',
      title: 'Contact us',
    },
    about: {
      slug: '/about',
      title: 'About us',
    },
    locations: {
      slug: '/locations',
      title: 'Locations',
    },
    services: {
      slug: '/services',
      title: `Services`,
    },
    studioSpace: {
      slug: '/studio-space',
      title: 'Studio space',
    },
    specialist: {
      slug: '/specialist',
      title: 'Specialist',
    },
  },
};

export const DATE_FORMAT = {
  /** `dd/MM/yyyy` */
  long: 'dd/MM/yyyy',
  /** `d/M/yy` */
  short: 'd/M/yy',
};

export const DATETIME_FORMAT = {
  /** `dd/MM/yyyy HH:mm:ss` */
  long: 'dd/MM/yyyy HH:mm:ss',
  /** `dd/MM/yyyy HH:mm` */
  medium: 'dd/MM/yyyy HH:mm',
  /** `d/M/yy H:mm` */
  short: 'd/M/yy H:mm',
};

export const sanityConfig = {
  // Find your project ID and dataset in `sanity.json` in your studio project
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT || '',
  useCdn: process.env.NODE_ENV === 'production',
  apiVersion: '2021-04-07',
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
};

export const REVALIDATE_TIME = 60;
