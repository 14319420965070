import { FC } from 'react';
import styled from 'styled-components';
import { generateCustomPropertyKey, Theme } from 'styles/theme';

interface StripeListItemProps {
  borderColor?: keyof Theme['colors'];
}

const ListItem = styled.li`
  padding-left: var(--space-4);
  list-style: none;
  border-left: 20px solid var(--border-color, currentColor);
`;

const StripeListItem: FC<StripeListItemProps> = ({ children, borderColor }) => {
  const borderColorVal =
    borderColor && `var(${generateCustomPropertyKey('colors', borderColor)})`;

  return (
    <ListItem style={{ '--border-color': borderColorVal }}>{children}</ListItem>
  );
};

export default StripeListItem;
