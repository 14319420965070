import styled from 'styled-components';

const TextBlock = styled.div`
  max-width: 75ch;

  > * + * {
    margin-top: var(--space-3);
  }

  ul,
  ol {
    list-style-position: inside;
  }

  /* Adjacent paragraph elements */
  p + p {
    margin-top: var(--space-1);
  }

  /* Not ideal but a work around for the "TextBlock &" selector not working within the context of the Heading component */
  h1 {
    --font-size: var(--font-size-x-large);
  }

  h2 {
    --font-size: var(--font-size-large);
  }

  h3 {
    --font-size: var(--font-size-medium);
  }

  h4 {
    --font-size: var(--font-size-small);
  }
`;

export default TextBlock;
