import { FC, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  ref?: ForwardedRef<HTMLInputElement>;
}

const Label = styled.label`
  display: block;
`;

const CheckboxStyled = styled.input`
  position: relative;
  width: 24px;
  height: 24px;
  background-color: var(--color-white);
  border: 1px solid currentColor;
  appearance: none;

  &[type='radio'] {
    border-radius: 100%;

    &:checked {
      background-color: var(--color-black);
      box-shadow: inset 0 0 0 4px var(--color-white);
    }
  }

  &[type='checkbox'] {
    border-radius: 5px;

    &::after {
      position: absolute;
      top: 5px;
      left: 3px;
      width: 15px;
      height: 8px;
      border: 3px solid currentColor;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      opacity: 0;
      content: '';
    }

    &:checked::after {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: var(--space-2);
  align-items: center;
`;

const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, ...props }, ref) => {
    return (
      <Wrapper>
        <CheckboxStyled id={id} {...props} ref={ref} />
        <Label htmlFor={id}>{label}</Label>
      </Wrapper>
    );
  }
);

export default Checkbox;

Checkbox.defaultProps = {
  type: 'checkbox',
};
