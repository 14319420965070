import { ErrorMessage } from '@hookform/error-message';
import React, {
  ElementType,
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  id: string;
  label: string;
  as?: ElementType;
  ref?: ForwardedRef<HTMLInputElement>;
  errors?: any;
}

const Label = styled.label`
  display: block;
  margin-bottom: var(--space-0);
`;

export const inputBase = css`
  width: 100%;
  height: 40px;
  padding: var(--space-1) var(--space-2);
  border: 1px solid currentColor;
  border-radius: var(--radius-small);
`;

const InputStyled = styled.input`
  ${inputBase}

  textarea& {
    height: auto;
    min-height: 184px;
  }
`;

const Error = styled.p`
  margin-top: var(--space-0);
  font-weight: bold;
`;

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ label, id, as, errors, ...props }, ref) => {
    return (
      <div>
        <Label htmlFor={id}>{label}</Label>
        <InputStyled id={id} as={as} {...props} ref={ref} />
        {errors && (
          <ErrorMessage errors={errors} name={props.name || id} as={Error} />
        )}
      </div>
    );
  }
);

export default Input;
