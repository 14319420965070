import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  > * + * {
    margin-left: var(--space-1);
  }

  @supports (gap: 1px) {
    gap: var(--space-1);

    > * {
      margin-left: 0;
    }
  }
`;

export default ButtonGroup;
