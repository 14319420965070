import BlockContent from 'components/block-content';
import { HeroProps } from 'components/hero';
import { Button, ButtonGroup, Stack } from 'components/ui';
import {
  createImageUrlBuilder,
  createPreviewSubscriptionHook,
} from 'next-sanity';
import Link from 'next/link';
import { sanityConfig } from 'settings/config';
import styled from 'styled-components';

const UnderlinedTextLink = styled.a`
  display: block;
  font-weight: bold;
  font-size: var(--font-size-xxx-small);
  text-decoration: underline;
`;

export const imageBuilder = createImageUrlBuilder(sanityConfig);

export const urlForImage = (source: any) =>
  imageBuilder.image(source).auto('format').fit('max');

export const usePreviewSubscription =
  createPreviewSubscriptionHook(sanityConfig);

export const getFlourishPosition = (flourish?: Sanity.Schema.Flourish) => {
  let flourishTopLeft;
  let flourishBottomRight;

  if (flourish?.position === 'topLeft' || flourish?.position === 'both') {
    flourishTopLeft = flourish.type;
  }

  if (flourish?.position === 'bottomRight' || flourish?.position === 'both') {
    flourishBottomRight = flourish.type;
  }

  return {
    flourishTopLeft,
    flourishBottomRight,
  };
};

/**
 * Get all the relevant props for the Hero component given a Sanity hero object
 */
export const getHeroProps = (hero?: Sanity.Schema.Hero): HeroProps => {
  const title = hero?.title || '';
  const description = hero?.text && <BlockContent blocks={hero.text} />;
  const image = hero?.image && {
    src: urlForImage(hero.image).url() || '',
    fill: hero.image.fill,
    alt: hero.image.alt,
  };
  const backgroundColor = hero?.background;
  const cta = (
    <>
      <Stack space={1}>
        <ButtonGroup>
          {hero?.primaryCTA && hero.primaryCTA.link && hero.primaryCTA.text && (
            <>
              <Link href={`${hero.primaryCTA.link}`} passHref>
                <Button as='a'>{hero.primaryCTA.text}</Button>
              </Link>
            </>
          )}
          {hero?.secondaryCTA &&
            hero.secondaryCTA.link &&
            hero.secondaryCTA.text && (
              <>
                <Link href={`${hero.secondaryCTA.link}`} passHref>
                  <Button as='a' variant='primary'>
                    {hero.secondaryCTA.text}
                  </Button>
                </Link>
              </>
            )}
        </ButtonGroup>
        {hero?.tertiaryCTA &&
          (hero.tertiaryCTA.link || hero.tertiaryCTA.externalLink) &&
          hero.tertiaryCTA.text && (
            <>
              <Link
                href={`${
                  hero.tertiaryCTA.link || hero.tertiaryCTA.externalLink
                }`}
                passHref
              >
                <Button as='a' variant='primary'>
                  {hero.tertiaryCTA.text}
                </Button>
              </Link>
            </>
          )}
      </Stack>
    </>
  );

  return {
    title,
    description,
    image,
    backgroundColor,
    pattern: hero?.pattern,
    cta,
    ...getFlourishPosition(hero?.flourish),
  };
};

export const formatAddress = (location: Sanity.Schema.Location) => {
  return `${location.address1}${
    location.address2 ? `, ${location.address2}` : ''
  }\n${location.city} ${location.state} ${location.postCode}`;
};

export const formatAddressWeblink = (location: Sanity.Schema.Location) => {
  return `${location.address1}${
    location.address2 ? `, ${location.address2}` : ''
  } ${location.city} ${location.state} ${location.postCode}`;
};
