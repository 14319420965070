import { gsap } from 'gsap';
import { FC } from 'react';
import { ReactComponent as FlourishMediumGreenSVG } from './flourish-medium-green.svg';
import { ReactComponent as FlourishMediumYellowSVG } from './flourish-medium-yellow.svg';
import { ReactComponent as FlourishSmallSVG } from './flourish-small.svg';

export const FlourishTypes = {
  small: 'small',
  mediumGreen: 'mediumGreen',
  mediumYellow: 'mediumYellow',
} as const;

export interface FlourishProps {
  variant: keyof typeof FlourishTypes;
  animate?: boolean;
  className?: string;
}

const commonAnimationProps: gsap.TweenVars = {
  repeat: -1,
  duration: 'random(5, 8)',
  ease: 'sine.inOut',
  repeatRefresh: true,
  yoyo: true,
};

const initAnimation = (svg: SVGSVGElement | null) => {
  let animations: {
    moveX: gsap.core.Tween;
    moveY: gsap.core.Tween;
    rotate: gsap.core.Tween;
  }[] = [];

  // Kick off animations once the ref is created
  if (svg !== null) {
    const paths = svg.getElementsByTagName('path');
    Array.from(paths).forEach((path) => {
      const moveX = gsap.to(path, {
        ...commonAnimationProps,
        x: 'random(-10, 10)',
      });
      const moveY = gsap.to(path, {
        ...commonAnimationProps,
        y: 'random(-15, 15)',
      });
      const rotate = gsap.to(path, {
        ...commonAnimationProps,
        rotate: 'random(-5, 5)',
      });

      animations.push({ moveX, moveY, rotate });
    });
  } else {
    // Clean up
    animations.forEach((animation) => {
      animation.moveX.kill();
      animation.moveY.kill();
      animation.rotate.kill();
    });
  }
};

const Flourish: FC<FlourishProps> = ({
  variant,
  animate = true,
  className,
  ...props
}) => {
  let FlourishSVG: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;

  switch (variant) {
    case 'mediumGreen':
      FlourishSVG = FlourishMediumGreenSVG;
      break;
    case 'mediumYellow':
      FlourishSVG = FlourishMediumYellowSVG;
      break;
    case 'small':
      FlourishSVG = FlourishSmallSVG;
      break;
    default:
      FlourishSVG = FlourishSmallSVG;
      break;
  }

  return (
    <FlourishSVG
      ref={(ref) => animate && initAnimation(ref)}
      data-variant={variant}
      className={className}
      {...props}
    />
  );
};

export default Flourish;
